import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGIN,
    LOGOUT,
    MANTENER_SESION,
    MANTENER_SESION_EXITO,
    MANTENER_SESION_ERROR,
    ACTUALIZAR_USER_AUTH,
    CAMBIAR_SUCURSAL,
    CAMBIAR_SUCURSAL_ERROR,
    ACTUALIZAR_USER_STORAGE,
    ACTUALIZAR_USER_STORAGE_EXITO,
    ACTUALIZAR_USER_STORAGE_ERROR
} from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';
import { getEstadisticasAction } from './estadisticasActions';

export function iniciarSesionAction(credenciales) {
    return async (dispatch) => {
        dispatch( iniciarSesion() );

        try {
            const response = await clienteAxios.post('login', credenciales);
            if (response.data.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
                dispatch(iniciarSesionExito(response.data.data));
            }
        } catch (error) {
            dispatch( iniciarSesionError(true) );
        }
    }
}

export function logoutAction() {
    return async (dispatch) => {
        try {
            const response = await clienteAxios.get('logout');
            localStorage.removeItem("user");
            dispatch(logout());
        } catch (error) {
            console.error(error);
        }
    }
}

const iniciarSesion = () => ({
    type: LOGIN
})

const iniciarSesionExito = userData => ({
    type: LOGIN_SUCCESS,
    payload: userData
})

const iniciarSesionError = estado => ({
    type: LOGIN_FAILED,
    payload: estado
})

export const logout = () => ({
    type: LOGOUT
});

export function mantenerSesionAction() {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( mantenerSesion() )

        try {
            const response = await clienteAxios.post('usuarios/mantener-sesion');
            if(response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');

                let user = JSON.parse(localStorage.getItem("user"));
                user.token = response.data.data.token;
                user.mantener_sesion = response.data.data.mantener_sesion;
                localStorage.setItem("user", JSON.stringify(user));

                dispatch( mantenerSesionExito(user) );
            }
        } catch (error) {
            //Swal.fire('UPS', error.response.message, 'error');
            dispatch( mantenerSesionError(error) )
        }

        dispatch( loadingRequest(false) );
    }
}

const mantenerSesion = () => ({
    type: MANTENER_SESION
})

const mantenerSesionExito = (data) => ({
    type: ACTUALIZAR_USER_AUTH,
    payload: data
})

const mantenerSesionError = error => ({
    type: MANTENER_SESION_ERROR,
    payload: error
})

export function cambiarSucursalAction(idSucursal) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( cambiarSucursal() )

        try {
            const response = await clienteAxios.post('usuarios/cambiar-sucursal', {id_sucursal: idSucursal});
            if(response.data.data) {
                let user = JSON.parse(localStorage.getItem("user"));
                user.id_sucursal = response.data.data.id_sucursal
                localStorage.setItem("user", JSON.stringify(user));

                dispatch( cambiarSucursalExito(user) );
                window.location.reload(false);
            }
        } catch (error) {
            //Swal.fire('UPS', error.response.message, 'error');
            dispatch( cambiarSucursalError(error) )
        }

        dispatch( loadingRequest(false) );
    }
}

const cambiarSucursal = () => ({
    type: CAMBIAR_SUCURSAL
})

const cambiarSucursalExito = (data) => ({
    type: ACTUALIZAR_USER_AUTH,
    payload: data
})

const cambiarSucursalError = error => ({
    type: CAMBIAR_SUCURSAL_ERROR,
    payload: error
})

export function actualizarStorageAction(userData) {
    return async (dispatch) => {
        dispatch( actualizarStorage() )

        try {
            localStorage.setItem("user", JSON.stringify(userData));
            dispatch( actualizarStorageExito(userData) );
        } catch (error) {
            dispatch( actualizarStorageError(error) )
        }
    }
}

const actualizarStorage = () => ({
    type: ACTUALIZAR_USER_STORAGE
})

const actualizarStorageExito = (data) => ({
    type: ACTUALIZAR_USER_STORAGE_EXITO,
    payload: data
})

const actualizarStorageError = error => ({
    type: ACTUALIZAR_USER_STORAGE_ERROR,
    payload: error
})