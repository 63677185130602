
export const Routes = {
    // pages
    DashboardOverview: { path: "/" },
    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    Upgrade: { path: "/upgrade" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    Signin: { path: "/login" },
    Signup: { path: "/examples/sign-up" },
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },
    Usuarios: { path: "/usuarios"},
    Roles: { path: "/roles"},
    Menu: { path: "/menu"},
    Pacientes: { path: "/pacientes"},
    Tratamientos: { path: "/tratamientos"},
    Empleados: { path: "/empleados"},
    Agenda: { path: "/agenda"},
    Venta: { path: "/venta"},
    HistorialVentas: { path: "/historial-ventas"},
    Caja: { path: "/caja"},
    Productos: { path: "/productos"},
    CategoriasProductos: { path: "/categorias-productos"},
    Inventario: { path: "/inventario"},
    HistorialComisiones: { path: "/historial-comisiones"},
    HistorialCierreCaja: { path: "/historial-cierre-caja"},
    Configuracion: { path: "/configuracion"},
    PacientesFinanciados: { path: "/pacientes-financiados"},
    Kardex: { path: "/kardex"},
    Notificaciones: { path: "/notificaciones"},
    Impuestos: { path: "/impuestos"},
    HistorialCitas: { path: "/historial-citas"},
    MensajesMasivos: { path: "/mensajes-masivos"},
    Calendario: { path: "/calendario"},
    Reportes: { path: "/reportes/:nombre"},
    Catalogos: { path: "/catalogos/:nombre"},
    EmpleadosPorcentajes: { path: "/empleados-porcentajes"},
    Sucursales: { path: "/sucursales"},
    Nominas: { path: "/nominas" },
    Proveedores: { path: "/proveedores" },
    FinanciamientosProveedores: { path: "/financiamientos-proveedores" },
    PuestosLaborales: { path: "/puestos-laborales" },
    Estadisticas: { path: "/estadisticas" },
    GastosOperacion: { path: "/gastos-operacion" },
    WhatsappChat: { path: "/whatsapp-chat" },
    Prospectos: { path: "/prospectos" },

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};