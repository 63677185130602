import Swal from 'sweetalert2';

export function ConfirmModal (
    callbacks,
    ignorarConfirmacion = false,
    title = null,
    icon = 'warning'
) {
    Swal.fire({
        title: (!title) ? '¿Estás seguro de realizar esta acción?' : title,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si'
      }).then((result) => {
        if (result.isConfirmed || ignorarConfirmacion) {
            callbacks.forEach(callback => {
                if (callback.args && callback.args.length > 0) {
                    callback.args = callback.args.map(arg => {
                        if (typeof arg === 'object') {
                            arg.isConfirmed = result.isConfirmed;
                        }

                        return arg;
                    });
                }

                if(callback.action) {
                    callback.event(callback.action.apply(callback.action, callback.args));
                } else {
                    callback.event.apply(callback.event, callback.args ?? []);
                } 
            });
        }
      })
}
