import React, { useState, useEffect } from "react";
import {
  Card,
  Modal,
  Form,
  Button,
  Row,
  Col,
  Tabs,
  Tab,
  Nav,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  agregarPacienteAction,
  cerrarModalAgregarAction,
} from "../../actions/pacientesActions";
import { getDatosAdicionalesAction } from "../../actions/datosAdicionalesActions";
import DatosAdicionalesRender from "../../components/DatosAdicionales/DatosAdicionalesRender";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";
import { getTratamientosAction } from "../../actions/tratamientosActions";
import { getProductosAction } from "../../actions/productosActions";

export default (props) => {
  const dispatch = useDispatch();

  const [showModalAgregar, setShowModalAgregar] = useState(false);
  const { datosAdicionales, tabsDatosAdicionales } = useSelector(
    (state) => state.datosAdicionales
  );
  const { datosAdicionalesDetalles } = useSelector(
    (state) => state.datosAdicionalesDetalles
  );

  const { tratamientos } = useSelector((state) => state.tratamientos);

  const { loadingProductosVenta, productos } = useSelector(
    (state) => state.venta
  );

  const [agregar, guardar] = useState({
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    fecha_nacimiento: "",
    genero: "",
    telefono: "",
    correo_electronico: "",
    es_prospecto: true,
    productos_servicios: [],
  });

  useEffect(() => {
    setShowModalAgregar(props.showModal);
  }, [props.showModal]);

  useEffect(() => {
    dispatch(getProductosAction());
    dispatch(getTratamientosAction());
  }, []);

  const submitForm = (e) => {
    e.preventDefault();

    const datos_adicionales = {
      detalles: datosAdicionalesDetalles,
      tabla: "CLIENTES",
      id_tabla: null,
    };
    agregar.datos_adicionales = datos_adicionales;

    clienteAxios.post("clientes/prospectos", agregar).then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        const prospectos = respuesta.data.data;

        agregar.nombre = "";
        agregar.apellido_paterno = "";
        agregar.apellido_materno = "";
        agregar.correo_electronico = "";
        agregar.genero = "";
        agregar.telefono = "";
        agregar.fecha_nacimiento = "";

        Swal.fire("¡Muy bien!", respuesta.data.message, "success");
      }
    });
  };

  const handleClose = () => {
    setShowModalAgregar(false);
    props.onHide();
  };

  const onChangeForm = (e) => {
    guardar({
      ...agregar,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showModalAgregar}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title className="h6">Agregar prospecto</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Tab.Container defaultActiveKey="generales">
            <Row>
              <Nav fill variant="tabs" className="mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="generales">Generales</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="productos_servicios">
                    Servicios/Productos
                  </Nav.Link>
                </Nav.Item>
                {tabsDatosAdicionales.map((dato, index) => (
                  <Nav.Item>
                    <Nav.Link eventKey={dato.tabulador}>
                      {dato.tabulador}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Row>
            <Tab.Content>
              <Tab.Pane eventKey="generales" title="Generales">
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Nombre(s)*</Form.Label>
                      <Form.Control
                        type="text"
                        name="nombre"
                        placeholder="Nombre(s)"
                        value={agregar.nombre}
                        onChange={onChangeForm}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Apellido paterno*</Form.Label>
                      <Form.Control
                        type="text"
                        name="apellido_paterno"
                        placeholder="Apellido paterno"
                        value={agregar.apellido_paterno}
                        onChange={onChangeForm}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Apellido materno</Form.Label>
                      <Form.Control
                        type="text"
                        name="apellido_materno"
                        placeholder="Apellido materno"
                        value={agregar.apellido_materno}
                        onChange={onChangeForm}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha nacimiento</Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha_nacimiento"
                    placeholder="Fecha de nacimiento"
                    value={agregar.fecha_nacimiento}
                    onChange={onChangeForm}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label as="legend" column="sm">
                    Genero
                  </Form.Label>
                  <br />
                  <Form.Check
                    inline
                    type="radio"
                    name="genero"
                    label="Masculino"
                    value="M"
                    onChange={onChangeForm}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    name="genero"
                    label="Femenino"
                    value="F"
                    onChange={onChangeForm}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Correo electronico</Form.Label>
                  <Form.Control
                    type="email"
                    name="correo_electronico"
                    placeholder="Correo electronico"
                    value={agregar.correo_electronico}
                    onChange={onChangeForm}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Teléfono*</Form.Label>
                  <Form.Control
                    type="number"
                    name="telefono"
                    placeholder="Teléfono"
                    value={agregar.telefono}
                    onChange={onChangeForm}
                  />
                </Form.Group>
              </Tab.Pane>
              {tabsDatosAdicionales.map((dato, index) => (
                <Tab.Pane
                  eventKey={dato.tabulador}
                  title={dato.tabulador}
                  key={index}
                >
                  <DatosAdicionalesRender
                    tabulador={dato.tabulador}
                    id_tabla={null}
                  />
                </Tab.Pane>
              ))}
              <Tab.Pane
                eventKey="productos_servicios"
                title="Servicios/Productos"
              >
                <Tab.Container defaultActiveKey="servicios">
                  <Row>
                    <Nav variant="tabs" className="mb-3">
                      <Nav.Item>
                        <Nav.Link eventKey="servicios">Servicios</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="productos">Productos</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Row>
                  <Tab.Content>
                    <Tab.Pane eventKey="servicios" title="Servicios">
                      <Row xs={2} sm={2} lg={3} xl={4} className="g-4">
                        {tratamientos.map((tratamiento) => {
                          return (
                            <Col key={`tratamiento-${tratamiento.id}`}>
                              <Card className="text-center h-100">
                                {tratamiento.imagen ? (
                                  <Card.Img
                                    variant="top"
                                    src={
                                      process.env.REACT_APP_API_BASE_URL +
                                      "/image/" +
                                      tratamiento.imagen
                                    }
                                    style={{
                                      height: "130px",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <Card.Img
                                    variant="top"
                                    src="https://www.lwf.org/images/emptyimg.png"
                                    style={{
                                      height: "130px",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                                <Card.Body style={{ padding: "10px" }}>
                                  <Card.Title style={{ fontSize: "15px" }}>
                                    {tratamiento.nombre}
                                  </Card.Title>
                                </Card.Body>
                                <Card.Footer
                                  style={{ padding: "6px", fontWeight: "bold" }}
                                >
                                  <Button className="btn-sm" variant="primary">
                                    Agregar
                                  </Button>
                                </Card.Footer>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="productos" title="Productos"></Tab.Pane>
                  </Tab.Content>
                </Tab.Container>

                <hr />

                <Card border="light" className="shadow-sm">
                  <Card.Body>
                    <table className="table table-centered table-nowrap">
                      <thead>
                        <tr>
                          <th scope="col">Nombre</th>
                          <th scope="col">Precio</th>
                          <th scope="col">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {agregar.productos_servicios.map((producto, index) => (
                          <tr key={index}>
                            <td>{producto.nombre}</td>
                            <td>{producto.precio}</td>
                            <td>
                              <Button
                                variant="danger"
                                onClick={() => {
                                  agregar.productos_servicios =
                                    agregar.productos_servicios.filter(
                                      (prod) => prod.id !== producto.id
                                    );
                                }}
                              >
                                Eliminar
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Card.Body>
                </Card>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
