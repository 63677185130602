import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Breadcrumb } from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "../../components/DataTable";
import {
  comenzarEditarTratamientoAction,
  comenzarAgregarTratamientoAction,
  getTratamientosAction,
  eliminarTratamientoAction,
} from "../../actions/tratamientosActions";

import {
  comenzarAgregarTratamientoPrecioAction,
  getTratamientosPreciosAction,
  comenzarListaAction,
} from "../../actions/tratamientosPreciosActions";

import { ConfirmModal } from "../../components/ConfirmModal";
import AgregarTratamiento from "./AgregarTratamiento";
import EditarTratamiento from "./EditarTratamiento";
import TratamientosPrecios from "./TratamientosPrecios";

import EmptyImg from "../../assets/img/emptyimg.png";
import { columnaSucursalDT } from "../../helpers";

import {
  getDatosAdicionalesAction,
  getTabsDatosAdicionalesAction,
  setModalDatosAdicionalesAction,
} from "../../actions/datosAdicionalesActions";
import DatosAdicionales from "../../components/DatosAdicionales/DatosAdicionales";
import { getDatosAdicionalesDetallesAction } from "../../actions/datosAdicionalesDetallesActions";

export default () => {
  const dispatch = useDispatch();

  const { loadingTratamientos, tratamiento, tratamientos } = useSelector(
    (state) => state.tratamientos
  );

  useEffect(() => {
    dispatch(getTratamientosAction());
  }, []);

  let columns = [
    {
      text: "Imagen",
      align: "center",
      sortable: false,
      width: "8%",
      cell: (record) => {
        return (
          <img
            src={
              record.imagen
                ? process.env.REACT_APP_API_BASE_URL + "/image/" + record.imagen
                : EmptyImg
            }
            alt={record.nombre}
            width={50}
            height={50}
            style={{ objectFit: "contain" }}
          />
        );
      },
    },
    {
      key: "id",
      text: "ID",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "nombre",
      text: "Nombre",
      className: "nombre",
      align: "left",
      sortable: true,
    },
  ];
  
  const state = {
    records: tratamientos,
  };

  const extraButtons = [
    {
      className: "btn btn-info",
      title: "Agregar tratamiento",
      children: [
        <span>
          <i class="fas fa-plus"></i> Nuevo
        </span>,
      ],
      onClick: (event) => {
        dispatch(comenzarAgregarTratamientoAction());
        dispatch(getTabsDatosAdicionalesAction("TRATAMIENTOS"));
        dispatch(getDatosAdicionalesAction("TRATAMIENTOS"));
      },
    },
    {
      className: "btn btn-primary",
      title: "Datos adicionales",
      children: [
        <span>
          <i class="fas fa-plus"></i> Datos adicionales
        </span>,
      ],
      onClick: (event) => {
        dispatch(setModalDatosAdicionalesAction(true));
        dispatch(getDatosAdicionalesAction("TRATAMIENTOS"));
      },
    },
  ];

  const editRecord = (record) => {
    dispatch(comenzarEditarTratamientoAction(record));
    dispatch(getTabsDatosAdicionalesAction("TRATAMIENTOS"));
    dispatch(getDatosAdicionalesAction("TRATAMIENTOS", record.id));
    dispatch(getDatosAdicionalesDetallesAction("TRATAMIENTOS", record.id));
  };

  const deleteRecord = (record) => {
    const callbacks = [
      {
        event: dispatch,
        action: eliminarTratamientoAction,
        args: [record],
      },
    ];

    ConfirmModal(callbacks);
  };

  const precios = (record) => {
    dispatch(getTratamientosPreciosAction(record));
  };

  const acciones = [
    {
      description: "Precios",
      icon: "fas fa-money-check-alt",
      className: "btn btn-success btn-sm",
      event: precios,
      style: { marginRight: "5px" },
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Tratamientos</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Tratamientos</h5>
          <DataTable
            columns={columns}
            editRecord={editRecord}
            deleteRecord={deleteRecord}
            acciones={acciones}
            state={state}
            extraButtons={extraButtons}
            loading={loadingTratamientos}
          />
        </Card.Body>
      </Card>

      <AgregarTratamiento />
      <EditarTratamiento />
      <TratamientosPrecios />
      <DatosAdicionales tabla="TRATAMIENTOS" />
    </>
  );
};
