import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGIN,
    LOGOUT,
    ACTUALIZAR_USER_AUTH,
    ACTUALIZAR_USER_STORAGE_EXITO
} from '../types';

const user = JSON.parse(localStorage.getItem("user"));

//cada reducer tiene su propio state
const initialState = {
    username: '',
    password: '',
    user: user ? user : null,
    loading: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LOGIN:
            return {
                ...state,
                loading: true
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload
            }
        case LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case LOGOUT:
            return {
                ...state,
                user: null
            }
        case ACTUALIZAR_USER_AUTH:
            return {
                ...state,
                user: action.payload
            }
        case ACTUALIZAR_USER_STORAGE_EXITO:
            return {
                ...state,
                user: action.payload
            }
        default:
            return state;
    }
}