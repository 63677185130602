import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Modal,
  Button,
  Form,
  InputGroup,
  Row,
  ListGroup,
  Container,
  Nav,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
  ThemeProvider,
  Image,
  Tab as TabReact,
  Tabs as TabsReact,
  Dropdown,
  Badge,
} from "@themesberg/react-bootstrap";
import {
  getAgendaAction,
  eliminarAgendaAction,
  agregarAgendaAction,
  getHorariosDisponiblesAction,
  confirmarCitaAction,
  actualizarAgendaAction,
  confirmarAsistenciaAction,
} from "../../../actions/agendaActions";
import {
  comenzarAgregarPacienteAction,
  getPacientesAction,
} from "../../../actions/pacientesActions";
import RevoCalendar from "revo-calendar-jzepeda";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faCalendarAlt,
  faCheckSquare,
  faEdit,
  faInfo,
  faInfoCircle,
  faPhone,
  faStore,
  faTrash,
  faUser,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clienteAxios from "../../../config/axios";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import DefaultUser from "../../../assets/img/team/default-user.png";
import "react-datetime/css/react-datetime.css";
import Filtro from "../../../components/Filtro";
import { formatAMPM } from "../../../helpers";
import { getEmpleadosConAgendaActivaAction } from "../../../actions/empleadosActions";
import { limpiarFiltroAction } from "../../../actions/filtroActions";
import AgregarPaciente from "../../../pages/Pacientes/AgregarPaciente";
import { Fragment } from "react";
import Carousel, { consts } from "react-elastic-carousel";
import { SplitButton } from "primereact/splitbutton";
import { Avatar } from "primereact/avatar";
import DefaultPicture from "../../../assets/img/team/default-user.png";
import {
  Tabs,
  DragTabList,
  PanelList,
  Panel,
  ExtraButton,
  Tab,
  TabList,
} from "@react-tabtab-next/tabtab";
import * as customStyle from "./tabsTheme";
import { Card as PrimeCard } from "primereact/card";
import Scheduler from "./Scheduler";
import { useCalendarApp, ScheduleXCalendar } from "@schedule-x/react";
import {
  viewWeek,
  viewDay,
  viewMonthGrid,
  viewMonthAgenda,
} from "@schedule-x/calendar";

import "@schedule-x/theme-default/dist/index.css";
import { createEventModalPlugin } from "@schedule-x/event-modal";
import Select, { components } from "react-select";
import { createEventsServicePlugin } from "@schedule-x/events-service";
import { use } from "echarts";
import { createCurrentTimePlugin } from "@schedule-x/current-time";
import Typeahead from "../../../components/Typeahead";
import { createCalendarControlsPlugin } from "@schedule-x/calendar-controls";
import { obtenerSucursalesAction } from "../../../actions/sucursalesActions";
import { ConfirmModal } from "../../../components/ConfirmModal";

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();

  moment.locale("es", {
    week: {
      dow: 1, // Monday is the first day of the week.
    },
  });

  const tabNode = useRef(null);
  const tabList = useRef(null);
  const eventRef = useRef(null);

  const { agendas, horarios_disponibles } = useSelector(
    (state) => state.agenda
  );
  const { pacientes } = useSelector((state) => state.pacientes);
  const { empleados } = useSelector((state) => state.empleados);
  const { sucursales } = useSelector((state) => state.sucursales);
  const [empleadosSelect, setEmpleadosSelect] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [inputBusqueda, setInputBusqueda] = useState(null);
  const [sucursalesSelect, setSucursalesSelect] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [selectedDateRange, setSelectedDateRange] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [esMovil, setEsMovil] = useState(window.innerWidth <= 768);
  const [eventRender, setEventRender] = useState(false);

  const formatoAgenda = "YYYY-MM-DDTHH:mm:ss.000Z";
  const roles = user.roles;

  const [id_empleado_agenda, setDoctorAgenda] = useState(null);

  const { respuestaFiltro } = useSelector((state) => state.filtro);

  const [fechaSeleccionada, setFechaSeleccionada] = useState(
    moment().format("YYYY-MM-DD")
  );

  const modelo = "Agenda.Agenda";
  const opcionesFiltro = [
    {
      valor: "cliente_proximas_citas",
      label: "Nombre cliente",
      tipo: "text",
      criteria: ["agenda"],
    },
    {
      valor: "uuid",
      label: "Folio",
      tipo: "text",
      criteria: ["agenda"],
    },
  ];

  useEffect(() => {
    dispatch(getEmpleadosConAgendaActivaAction());
    dispatch(obtenerSucursalesAction());

    const manejadorResize = () => {
      setEsMovil(window.innerWidth <= 768);
    };

    window.addEventListener("resize", manejadorResize);
    console.log(esMovil);

    return () => {
      window.removeEventListener("resize", manejadorResize);
    };
  }, []);

  useEffect(() => {
    if (inputBusqueda && inputBusqueda != "") {
      obtenerCitas(inputBusqueda.id);
    }
  }, [inputBusqueda]);

  const eventsServicePlugin = createEventsServicePlugin();
  const viewsCalendar =
    roles.includes("DENTISTA") || esMovil
      ? [viewMonthAgenda]
      : [viewMonthAgenda];

  const calendarControls = createCalendarControlsPlugin();

  var calendar = useCalendarApp({
    defaultView: roles.includes("DENTISTA")
      ? viewMonthAgenda.name
      : viewMonthAgenda.name,
    views: viewsCalendar,
    events: [],
    plugins: [
      createEventModalPlugin(),
      eventsServicePlugin,
      createCurrentTimePlugin(),
      calendarControls,
    ],
    locale: "es-ES",
    selectedDate: selectedDateRange,
    callbacks: {
      onSelectedDateUpdate: (date) => {
        const fechaInicio = moment(date).startOf("month").format("YYYY-MM-DD");
        const fechaFin = moment(date).endOf("month").format("YYYY-MM-DD");

        setState({
          ...state,
          fechaInicio: fechaInicio,
          fechaFin: fechaFin,
        });

        state.fechaInicio = fechaInicio;
        state.fechaFin = fechaFin;
      },
      onClickAgendaDate(date) {
        setSelectedDateRange(date);
      },
    },
  });

  useEffect(() => {
    const values = empleados.map((empleado) => {
      return {
        value: empleado.id,
        label: empleado.nombre + " " + empleado.apellido_paterno,
      };
    });

    setEmpleadosSelect(values);

    if (roles.includes("DENTISTA")) {
      setSelectedEmpleados(values);
    }
  }, [empleados]);

  useEffect(() => {
    setSucursalesSelect(
      sucursales.map((sucursal) => {
        return {
          value: sucursal.id,
          label: sucursal.nombre,
        };
      })
    );
  }, [sucursales]);

  const [fecha, setFecha] = useState("");
  const [fechaForm, setFechaForm] = useState("");
  const [horaInicialForm, setHoraInicialForm] = useState("");
  const [horaFinalForm, setHoraFinalForm] = useState("");
  const [titulo, setTitulo] = useState("");
  const [nota, setNota] = useState(null);
  const [modalEvent, setModalEvent] = useState(false);
  const [agenda, setAgenda] = useState(null);
  const [selectedEmpleados, setSelectedEmpleados] = useState([]);
  const [selectedSucursales, setSelectedSucursales] = useState([]);
  const [selectedEstatus, setSelectedEstatus] = useState([]);

  const tabItems = useMemo(() => {
    return empleados.map((empleado, index) => {
      return (
        <Tab key={index}>
          <div className="media d-flex align-items-center">
            <Image
              src={DefaultPicture}
              className="user-avatar md-avatar rounded-circle"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                minWidth: "1.5rem",
                minHeight: "1.5rem",
              }}
            />
            <div className="media-body ms-2 text-dark align-items-center">
              <span className="mb-0 fw-bold">
                {" "}
                {empleado.nombre} {empleado.apellido_paterno}
              </span>
            </div>
          </div>
        </Tab>
      );
    });
  }, [empleados]);

  const panelItems = useMemo(() => {
    return empleados.map((tab, index) => {
      return <Panel key={index}></Panel>;
    });
  }, [empleados]);

  useEffect(() => {
    if (agendas) {
      calendar.events.set(agendas);
      /*if (inputBusqueda) {
        const fechaBusqueda = moment(inputBusqueda.fecha, formatoAgenda).format('YYYY-MM-DD');
        if(calendarControls) {
          calendarControls.setDate(fechaBusqueda);
        }
        
        setInputBusqueda(null);
      }*/
    }
  }, [agendas]);

  const [state, setState] = useState({
    query: "",
    queryComisionista: "",
    options: [],
    optionsComisionista: [],
    isLoading: false,
    isLoadingComisionistaSelect: false,
    clienteSeleccionado: null,
    comisionistaSeleccionado: null,
    selectedEstatus: [],
    fechaInicio: moment().startOf("month").format("YYYY-MM-DD"),
    fechaFin: moment().endOf("month").format("YYYY-MM-DD"),
    sucursalForm: null,
  });

  useEffect(() => {
    obtenerCitas();
  }, [
    selectedEmpleados,
    selectedSucursales,
    selectedEstatus,
    state.fechaInicio,
    state.fechaFin,
  ]);

  var _cacheCliente = {};
  var _cacheComisionista = {};

  const _handleInputChangeCliente = (query) => {
    state.query = query;
  };

  const _handleInputChangeComisionista = (query) => {
    state.queryComisionista = query;
  };

  const makeAndHandleRequestCliente = (query) => {
    const config = { params: { query } };

    return clienteAxios.get("typeahead/clientes", config).then((res) => {
      const options = res.data.data;
      return { options };
    });
  };

  const makeAndHandleRequestComisionista = (query) => {
    const config = { params: { query } };

    return clienteAxios.get("typeahead/empleadosAgenda", config).then((res) => {
      const options = res.data.data;
      return { options };
    });
  };

  const _handleSearchCliente = (query) => {
    if (_cacheCliente[query]) {
      setState({
        ...state,
        options: _cacheCliente[query].options,
      });
      return;
    }

    setState({
      ...state,
      isLoading: true,
    });

    makeAndHandleRequestCliente(query).then((resp) => {
      _cacheCliente[query] = { ...resp };
      setState({
        ...state,
        isLoading: false,
        options: resp.options,
      });
    });
  };

  const _handleSearchComisionista = (query) => {
    if (_cacheComisionista[query]) {
      setState({
        ...state,
        optionsComisionista: _cacheComisionista[query].options,
      });
      return;
    }

    setState({
      ...state,
      isLoadingComisionistaSelect: true,
    });

    makeAndHandleRequestComisionista(query).then((resp) => {
      _cacheComisionista[query] = { ...resp };
      setState({
        ...state,
        isLoadingComisionistaSelect: false,
        optionsComisionista: resp.options,
      });
    });
  };

  const onChangeCliente = (selectedOption) => {
    if (selectedOption.length > 0) {
      setState({
        ...state,
        clienteSeleccionado: selectedOption[0],
      });
    } else {
      setState({
        ...state,
        clienteSeleccionado: null,
      });
    }
  };

  const onChangeComisionista = (selectedOption) => {
    if (selectedOption.length > 0) {
      setState({
        ...state,
        comisionistaSeleccionado: selectedOption[0],
      });
    } else {
      setState({
        ...state,
        comisionistaSeleccionado: null,
      });
    }
  };

  const onChangeBusqueda = (selectedOption) => {
    if (selectedOption.length > 0) {
      setInputBusqueda(selectedOption[0]);
    } else {
      setInputBusqueda(null);
    }
  };

  const onChangeSucursalForm = (values) => {
    setState({
      ...state,
      sucursalForm: values,
    });
  };

  const addEvent = () => {
    setAgenda(null);

    const date = new Date(Date.parse(selectedDateRange));
    setFechaForm(date);
    setTitulo(null);
    setModalEvent(true);
    setNota(null);

    if (roles.includes("DENTISTA")) {
      setState({
        ...state,
        comisionistaSeleccionado: {
          id: empleados[0].id,
          label: empleados[0].nombre + " " + empleados[0].apellido_paterno,
        },
      });
    } else if (selectedEmpleados.length == 1) {
      setState({
        ...state,
        comisionistaSeleccionado: {
          id: selectedEmpleados[0].value,
          label: selectedEmpleados[0].label,
        },
      });
    }
  };

  const onConfirmEvent = (e) => {
    e.preventDefault();

    const data = {
      fecha:
        moment.utc(fechaForm).format("YYYY-MM-DD") +
        "T" +
        moment.utc(horaInicialForm).format("HH:mm") +
        ":00.000Z",
      titulo: titulo,
      hora_inicio: moment.utc(horaInicialForm).format("hh:mm A"),
      hora_fin: moment.utc(horaFinalForm).format("hh:mm A"),
      id_cliente: state.clienteSeleccionado?.id,
      id_empleado: state.comisionistaSeleccionado?.id,
      id_sucursal: state.sucursalForm?.value,
      nota: nota,
    };

    dispatch(agregarAgendaAction(data));
  };

  const onUpdateEvent = (e) => {
    e.preventDefault();

    const data = {
      fecha:
        moment.utc(fechaForm).format("YYYY-MM-DD") +
        "T" +
        moment.utc(horaInicialForm).format("HH:mm") +
        ":00.000Z",
      hora_inicio: moment.utc(horaInicialForm).format("hh:mm A"),
      hora_fin: moment.utc(horaFinalForm).format("hh:mm A"),
      titulo: titulo,
      id_cliente: state.clienteSeleccionado?.id,
      id_empleado: state.comisionistaSeleccionado?.id,
      id_sucursal: state.sucursalForm?.value,
      nota: nota,
    };

    dispatch(actualizarAgendaAction(agenda.row.id, data));
  };

  const deleteEvent = (idAgenda) => {
    const agendaSeleccionada = agendas.filter(
      (agenda) => agenda.id == idAgenda
    )[0];

    const callbacks = [
      {
        event: dispatch,
        action: eliminarAgendaAction,
        args: [agendaSeleccionada],
      },
    ];

    ConfirmModal(callbacks);
  };

  const updateEvent = (idAgenda) => {
    const agendaSeleccionada = agendas.filter(
      (agenda) => agenda.id == idAgenda
    )[0];
    const date = new Date(Date.parse(agendaSeleccionada.row.fecha));

    const fechaEdicion = moment(
      date.getTime() + date.getTimezoneOffset() * 60000
    );

    if (agendaSeleccionada.row.hora_fin) {
      const dateString = date.toLocaleDateString("es-MX", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      const dateHoraFin = moment.utc(
        dateString + " " + agendaSeleccionada.row.hora_fin,
        "DD/MM/YYYY hh:mm A"
      );
      setHoraFinalForm(dateHoraFin.toDate().getTime());
    } else {
      const dateHoraFin = new Date(date.getTime() + 30 * 60000);
      setHoraFinalForm(dateHoraFin.getTime());
    }

    setModalEvent(true);
    setAgenda(agendaSeleccionada);
    setFechaForm(date.getTime());
    setHoraInicialForm(date.getTime());
    setTitulo(agendaSeleccionada.row.titulo);
    setNota(agendaSeleccionada.row.nota);
    setState({
      ...state,
      clienteSeleccionado: {
        id: agendaSeleccionada.row.id_cliente,
        nombre_completo: agendaSeleccionada.row.cliente.nombre_completo,
      },
      comisionistaSeleccionado: {
        id: agendaSeleccionada.row.id_empleado,
        label:
          agendaSeleccionada.row.empleado.nombre +
          " " +
          agendaSeleccionada.row.empleado.apellido_paterno,
      },
      sucursalForm: {
        value: agendaSeleccionada.row.sucursal.id,
        label: agendaSeleccionada.row.sucursal.nombre,
      },
    });
  };

  const deleteEventByIdAgenda = (id_agenda) => {
    dispatch(eliminarAgendaAction({ id: id_agenda }));
  };

  const handleEventModalClose = () => {
    setModalEvent(false);
  };

  const respuestaFiltroFooter = (item) => (
    <div className="flex flex-wrap justify-content-end gap-2">
      <Button
        className="d-block mx-auto"
        onClick={() => deleteEventByIdAgenda(item.id)}
        variant="danger"
        size="sm"
      >
        Eliminar
      </Button>
    </div>
  );

  const RespuestaBusqueda = () => {
    let resultado = [];
    if (respuestaFiltro.data) {
      respuestaFiltro.data.map((item, index) => {
        const date = new Date(Date.parse(item.fecha));

        resultado.push(
          <>
            <Col sm={4} md={3}>
              <PrimeCard
                title={item.titulo}
                footer={respuestaFiltroFooter(item)}
              >
                <ListGroup variant="flush">
                  <ListGroup.Item style={{ padding: "0px" }}>
                    <i class="fas fa-calendar"></i> {date.toUTCString()}
                  </ListGroup.Item>
                  <ListGroup.Item style={{ padding: "0px" }}>
                    <i class="fas fa-clock"></i> {formatAMPM(date, true)}
                  </ListGroup.Item>
                  <ListGroup.Item style={{ padding: "0px" }}>
                    <i class="fas fa-user"></i> {item.cliente?.nombre_completo}
                  </ListGroup.Item>
                </ListGroup>
              </PrimeCard>
            </Col>
          </>
        );
      });
    }

    return resultado;
  };

  const onChangeComisionistas = (values) => {
    setSelectedEmpleados(values);
  };

  const onChangeSucursales = (values) => {
    const idsSucursales = values.map((sucursal) => sucursal.value);

    if (!idsSucursales.includes(user.id_sucursal)) {
      values.push({
        value: user.id_sucursal,
        label: sucursales.filter(
          (sucursal) => sucursal.id == user.id_sucursal
        )[0].nombre,
      });
    }

    setSelectedSucursales(values);
  };

  const onChangeEstatus = (values) => {
    setSelectedEstatus(values);
  };

  const obtenerCitas = (idAgenda = null) => {
    if (idAgenda) {
      dispatch(getAgendaAction({ id_agenda: idAgenda }));
      return;
    }

    const sucursales = selectedSucursales.map((sucursal) => sucursal.value);

    const params = {
      idsEmpleados: selectedEmpleados.map((empleado) => empleado.value),
      sucursales: sucursales.length > 0 ? sucursales : [user.id_sucursal],
      estatus: selectedEstatus.map((estatus) => estatus.value),
      fecha_inicio: moment(state.fechaInicio).format("YYYY-MM-DD"),
      fecha_final: moment(state.fechaFin).format("YYYY-MM-DD"),
      id_agenda: idAgenda,
    };
    dispatch(getAgendaAction(params));
  };

  const timeConstraints = {
    minutes: {
      step: 5,
    },
  };

  const dateSelected = (date) => {
    const fecha = moment(date).format("YYYY-MM-DD");

    setFechaSeleccionada(fecha);
    setFecha(fecha);
    setFechaForm(fecha);
    if (id_empleado_agenda != null) {
      dispatch(getHorariosDisponiblesAction(id_empleado_agenda, fecha));
    }
  };

  const confirmarCita = (agenda) => {
    dispatch(confirmarCitaAction(agenda));
  };

  const confirmarAsistencia = (agenda) => {
    dispatch(confirmarAsistenciaAction(agenda));
  };

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <FontAwesomeIcon icon={faArrowCircleLeft} />
      ) : (
        <FontAwesomeIcon icon={faArrowCircleRight} />
      );
    return (
      <Button size="lg" variant="link" onClick={onClick} disabled={isEdge}>
        {pointer}
      </Button>
    );
  };

  const handleOnTabSequenceChange = useCallback(({ oldIndex, newIndex }) => {
    setActiveTab(newIndex);
  }, []);

  const handleOnTabChange = useCallback((i) => {
    setActiveTab(i);
  }, []);

  const handleTabList = () => {
    console.log(tabList);
  };

  const headerAgenda = (month) => {
    const empleado = empleados.filter((e) => e.id === id_empleado_agenda);

    return (
      <>
        <h3 className="text-center">
          {empleado.length > 0 ? empleado[0].nombre : ""}: {month}
        </h3>
      </>
    );
  };

  const SelectMenuComponent = (props) => {
    return <components.Menu {...props} className="z-3" />;
  };

  const toIntegers = (dateTimeSpecification) => {
    const hours = dateTimeSpecification.slice(11, 13),
      minutes = dateTimeSpecification.slice(14, 16);

    return {
      year: Number(dateTimeSpecification.slice(0, 4)),
      month: Number(dateTimeSpecification.slice(5, 7)) - 1,
      date: Number(dateTimeSpecification.slice(8, 10)),
      hours: hours !== "" ? Number(hours) : undefined,
      minutes: minutes !== "" ? Number(minutes) : undefined,
    };
  };

  const dateFn = (dateTimeString, locale) => {
    const { year, month, date } = toIntegers(dateTimeString);

    return new Date(year, month, date).toLocaleDateString(locale, {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const getLocalizedDate = dateFn;

  const timeFn = (dateTimeString, locale) => {
    const { year, month, date, hours, minutes } = toIntegers(dateTimeString);

    return new Date(year, month, date, hours, minutes).toLocaleTimeString(
      locale,
      {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }
    );
  };

  const getTimeStamp = (
    calendarEvent, // to facilitate testing. In reality, we will always have a full CalendarEventInternal
    locale,
    delimiter = "\u2013"
  ) => {
    const eventTime = { start: calendarEvent.start, end: calendarEvent.end };

    if (calendarEvent._isSingleDayFullDay) {
      return dateFn(eventTime.start, locale);
    }

    if (calendarEvent._isMultiDayFullDay) {
      return `${dateFn(eventTime.start, locale)} ${delimiter} ${dateFn(
        eventTime.end,
        locale
      )}`;
    }

    if (calendarEvent._isSingleDayTimed) {
      return `${dateFn(eventTime.start, locale)} ⋅ ${timeFn(
        eventTime.start,
        locale
      )} ${delimiter} ${timeFn(eventTime.end, locale)}`;
    }

    return `${dateFn(eventTime.start, locale)}, ${timeFn(
      eventTime.start,
      locale
    )} ${delimiter} ${timeFn(eventTime.end, locale)}`;
  };

  const CustomTimeGridEvent = ({ calendarEvent }) => {
    return (
      <div
        style={{
          backgroundColor: `var(--sx-color-primary-container)`,
          color: `var(--sx-color-on-primary-container)`,
          borderLeft: `4px solid var(--sx-color-primary)`,
          padding: `var(--sx-spacing-padding2)`,
          borderRadius: `var(--sx-rounding-extra-small)`,
        }}
        ref={eventRef}
      >
        <FontAwesomeIcon icon={faUser} /> <strong>{calendarEvent.title}</strong>{" "}
        <br />
        <FontAwesomeIcon icon={faCalendarAlt} />{" "}
        {getTimeStamp(calendarEvent, "es-ES")} <br />
        <FontAwesomeIcon icon={faInfoCircle} /> {calendarEvent.titulo_agenda}{" "}
        <br />
        <FontAwesomeIcon icon={faStore} /> {calendarEvent.row.sucursal.nombre}
      </div>
    );
  };

  const CustomEventModal = ({ calendarEvent }) => {
    return (
      <Fragment>
        <Row className="justify-content-end">
          <Col className="mb-2">
            {calendarEvent.row.confirmado ? (
              <Badge bg="success" style={{ width: "fit-content" }}>
                Confirmado
              </Badge>
            ) : (
              <Badge bg="warning" style={{ width: "fit-content" }}>
                Sin confirmar
              </Badge>
            )}
          </Col>
          <Col xs={1}>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Realizar venta</Tooltip>}
              >
                <FontAwesomeIcon
                  icon={faStore}
                  style={{ cursor: "pointer", color: "rgb(1, 1, 92)" }}
                  onClick={() => history.push('/venta?id_cliente=' + calendarEvent.row.id_cliente)}
                />
              </OverlayTrigger>
            </Col>
          {calendarEvent.row.confirmado == false && (
            <Col xs={1}>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Confirmar Cita</Tooltip>}
              >
                <FontAwesomeIcon
                  icon={faCheckSquare}
                  style={{ cursor: "pointer", color: "green" }}
                  onClick={() => confirmarCita(calendarEvent)}
                />
              </OverlayTrigger>
            </Col>
          )}
          {calendarEvent.row.asistio_cliente == false && (
            <Col xs={1}>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Confirmar llegada del cliente</Tooltip>}
              >
                <FontAwesomeIcon
                  icon={faUserCheck}
                  style={{ cursor: "pointer", color: "green" }}
                  onClick={() => confirmarAsistencia(calendarEvent)}
                />
              </OverlayTrigger>
            </Col>
          )}
          {roles.includes("DENTISTA") == false && (
            <>
              <Col xs={1}>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>Editar</Tooltip>}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ cursor: "pointer" }}
                    onClick={() => updateEvent(calendarEvent.id)}
                  />
                </OverlayTrigger>
              </Col>
              <Col xs={1}>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  overlay={<Tooltip>Eliminar</Tooltip>}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteEvent(calendarEvent.id)}
                  />
                </OverlayTrigger>
              </Col>
            </>
          )}
        </Row>
        <div className="sx__has-icon sx__event-modal__title">
          <div
            style={{
              backgroundColor: `var(--sx-color-${calendarEvent._color}-container)`,
            }}
            className="sx__event-modal__color-icon sx__event-icon"
          />

          {calendarEvent.titulo_agenda}
        </div>

        <div class="sx__has-icon sx__event-modal__time">
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="sx__event-icon"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M12 8V12L15 15"
                stroke="var(--sx-color-neutral-variant)"
                stroke-width="2"
                stroke-linecap="round"
              ></path>
              <circle
                cx="12"
                cy="12"
                r="9"
                stroke="var(--sx-color-neutral-variant)"
                stroke-width="2"
              ></circle>
            </g>
          </svg>
          {getTimeStamp(calendarEvent, "es-ES")}
        </div>
        <div class="sx__has-icon sx__event-modal__time">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="800px"
            height="800px"
            viewBox="0 0 24 24"
            fill="none"
            style={{
              width: "16px",
              height: "16px",
            }}
          >
            <path
              d="M5 21C5 17.134 8.13401 14 12 14C15.866 14 19 17.134 19 21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {calendarEvent.cliente}
        </div>
        <div class="sx__has-icon sx__event-modal__time">
          <FontAwesomeIcon icon={faPhone} /> {calendarEvent.telefono}
        </div>
        <div class="sx__has-icon sx__event-modal__time">
          <svg
            fill="#000000"
            height="800px"
            width="800px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 490 490"
            style={{
              width: "19px",
              height: "19px",
            }}
          >
            <g>
              <path
                d="M245,208.977c36.565,0,66.207-29.642,66.207-66.207S281.565,76.563,245,76.563s-66.208,29.642-66.208,66.207
		S208.435,208.977,245,208.977z M245,91.875c28.063,0,50.895,22.831,50.895,50.895c0,28.063-22.831,50.895-50.895,50.895
		c-28.064,0-50.896-22.831-50.896-50.895C194.104,114.706,216.936,91.875,245,91.875z"
              />
              <path
                d="M110.796,286.815c0,61.734,0,76.049,0,76.049s130.622,0,131.072,0l-63.971-138.678
		C177.898,224.186,110.796,225.082,110.796,286.815z M126.108,347.552v-60.737c0-34.228,27.182-43.702,42.448-46.323l49.385,107.06
		H126.108z"
              />
              <path
                d="M312.102,224.186l-63.971,138.678h131.073c0,0,0-14.315,0-76.049C379.204,225.082,312.102,224.186,312.102,224.186z
		 M363.892,347.552h-91.834l49.422-107.137c15.242,2.507,42.412,11.825,42.412,46.4V347.552z"
              />
              <polygon points="244.999,224.186 198.922,224.186 244.999,326.181 291.076,224.186 	" />
              <path
                d="M245,0C109.689,0,0,109.69,0,245s109.689,245,245,245c135.31,0,245-109.69,245-245S380.31,0,245,0z M245,474.688
		C118.349,474.688,15.313,371.65,15.313,245C15.313,118.349,118.349,15.313,245,15.313c126.65,0,229.688,103.037,229.688,229.688
		C474.688,371.65,371.65,474.688,245,474.688z"
              />
            </g>
          </svg>
          Comisionista:{" "}
          {calendarEvent.row.empleado.nombre +
            " " +
            calendarEvent.row.empleado.apellido_paterno}
        </div>
        {calendarEvent.subtitle && (
          <div class="sx__has-icon sx__event-modal__description">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="sx__event-icon"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <rect
                  x="4"
                  y="4"
                  width="16"
                  height="16"
                  rx="3"
                  stroke="var(--sx-color-neutral-variant)"
                  stroke-width="2"
                ></rect>
                <path
                  d="M16 10L8 10"
                  stroke="var(--sx-color-neutral-variant)"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M16 14L8 14"
                  stroke="var(--sx-color-neutral-variant)"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
              </g>
            </svg>
            {calendarEvent.subtitle}
          </div>
        )}

        <div class="sx__has-icon sx__event-modal__description">
          <FontAwesomeIcon icon={faStore} /> {calendarEvent.row.sucursal.nombre}
        </div>

        <div class="sx__event-modal__description">
          Creado por: {calendarEvent.row.creado_por?.name}
        </div>
      </Fragment>
    );
  };

  const onChangeHoraInicial = (value) => {
    const horaInicial = value.clone();

    setHoraInicialForm(value);
    setHoraFinalForm(horaInicial.add(5, "minutes"));
  };

  return (
    <>
      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm mt-4"
        style={{ paddingBottom: "220px" }}
      >
        <Card.Body>
          <h5 className="mb-4">Agenda</h5>

          <Row
            style={{ marginBottom: "10px" }}
            className="justify-content-md-end"
          >
            <Col xs={12} md={8}>
              <strong>Buscar cita</strong>
              <Typeahead
                url="typeahead/agenda"
                labelKey="label"
                id="async-agenda"
                placeholder="Buscar cita"
                onChange={(selected) => {
                  onChangeBusqueda(selected);
                }}
              />
            </Col>
            <Col xs={12} md={4}>
              <Dropdown style={{ float: "right", marginRight: "80px" }}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <i class="fas fa-plus"></i> Nuevo
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={addEvent}>Nueva cita</Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => dispatch(comenzarAgregarPacienteAction())}
                  >
                    Nuevo cliente
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={3}>
              {sucursalesSelect.length > 0 && (
                <>
                  <strong>Sucursales</strong>
                  <Select
                    options={sucursalesSelect}
                    isMulti
                    name="sucursales"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={sucursalesSelect.filter(
                      (sucursal) => sucursal.value === user.id_sucursal
                    )}
                    onChange={onChangeSucursales}
                    components={{
                      Menu: SelectMenuComponent,
                    }}
                  />
                </>
              )}
            </Col>
            <Col xs={12} md={3}>
              {empleados.length > 0 && (
                <>
                  <strong>Comisionistas</strong>
                  <Select
                    options={empleadosSelect}
                    isMulti
                    name="empleados"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={onChangeComisionistas}
                    components={{
                      Menu: SelectMenuComponent,
                    }}
                    defaultValue={() => {
                      if (roles.includes("DENTISTA")) {
                        return empleados.map((empleado) => {
                          return {
                            value: empleado.id,
                            label:
                              empleado.nombre + " " + empleado.apellido_paterno,
                          };
                        })[0];
                      }
                    }}
                    isDisabled={roles.includes("DENTISTA")}
                  />
                </>
              )}
            </Col>
            <Col xs={12} md={3}>
              <strong>Estatus</strong>
              <Select
                options={[
                  { value: "SIN_CONFIRMAR", label: "Sin confirmar" },
                  { value: "CONFIRMADO", label: "Confirmado" },
                  { value: "ELIMINADO", label: "Eliminado" },
                ]}
                isMulti
                name="estatus"
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={onChangeEstatus}
                components={{
                  Menu: SelectMenuComponent,
                }}
              />
            </Col>
          </Row>
          <br></br>
          {calendar && (
            <ScheduleXCalendar
              calendarApp={calendar}
              customComponents={{
                eventModal: CustomEventModal,
                monthAgendaEvent: CustomTimeGridEvent,
              }}
            />
          )}
        </Card.Body>
      </Card>

      <Modal
        as={Modal.Dialog}
        centered
        show={modalEvent}
        onHide={handleEventModalClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            {agenda ? "Editar cita" : "Agregar cita"}
          </Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleEventModalClose}
          />
        </Modal.Header>
        <Form onSubmit={agenda ? onUpdateEvent : onConfirmEvent}>
          <Modal.Body>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Titulo</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Titulo del evento"
                    onChange={(e) => setTitulo(e.target.value)}
                    value={titulo}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha</Form.Label>
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={false}
                    onChange={setFechaForm}
                    utc={true}
                    value={fechaForm}
                    initialViewMode="days"
                    dateFormat="YYYY-MM-DD"
                    timeConstraints={timeConstraints}
                    renderInput={(propsDate, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="text"
                          value={
                            fechaForm
                              ? moment.utc(fechaForm).format("YYYY-MM-DD")
                              : ""
                          }
                          placeholder="Selecciona una fecha*"
                          onFocus={openCalendar}
                          onChange={(e) => setFechaForm(e.target.value)}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Hora inicial</Form.Label>
                  <Datetime
                    timeFormat={true}
                    closeOnSelect={false}
                    onChange={onChangeHoraInicial}
                    utc={true}
                    value={horaInicialForm}
                    initialViewMode="time"
                    dateFormat="hh:mm A"
                    timeConstraints={timeConstraints}
                    renderInput={(propsDate, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="text"
                          value={
                            horaInicialForm
                              ? moment.utc(horaInicialForm).format("hh:mm A")
                              : ""
                          }
                          onFocus={openCalendar}
                          onChange={(e) => setHoraInicialForm(e.target.value)}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Hora final</Form.Label>
                  <Datetime
                    timeFormat={true}
                    closeOnSelect={false}
                    onChange={setHoraFinalForm}
                    utc={true}
                    value={horaFinalForm}
                    initialViewMode="time"
                    dateFormat="hh:mm A"
                    timeConstraints={timeConstraints}
                    renderInput={(propsDate, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="text"
                          value={
                            horaFinalForm
                              ? moment.utc(horaFinalForm).format("hh:mm A")
                              : ""
                          }
                          onFocus={openCalendar}
                          onChange={(e) => setHoraFinalForm(e.target.value)}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Cliente</Form.Label>
                  <AsyncTypeahead
                    {...state}
                    clearButton
                    id="async-clientes"
                    labelKey="nombre_completo"
                    minLength={2}
                    onInputChange={_handleInputChangeCliente}
                    onSearch={_handleSearchCliente}
                    onChange={onChangeCliente}
                    placeholder="Buscar cliente*"
                    renderMenuItemChildren={(option) => (
                      <div key={option.id}>
                        <img
                          alt={option.nombre_completo}
                          src={DefaultUser}
                          style={{
                            height: "24px",
                            marginRight: "10px",
                            width: "24px",
                          }}
                        />
                        <span>{option.nombre_completo}</span>
                      </div>
                    )}
                    useCache={false}
                    selected={
                      agenda
                        ? [
                            {
                              id: agenda.row.cliente.id,
                              nombre_completo:
                                agenda.row.cliente.nombre_completo,
                            },
                          ]
                        : undefined
                    }
                    disabled={agenda ? true : false}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Comisionista*</Form.Label>
                  <Typeahead
                    url="typeahead/empleados"
                    labelKey="label"
                    id="async-empleados"
                    placeholder="Buscar comisionista"
                    onChange={(selected) => {
                      onChangeComisionista(selected);
                    }}
                    selected={
                      state.comisionistaSeleccionado
                        ? [state.comisionistaSeleccionado]
                        : []
                    }
                    disabled={roles.includes("DENTISTA") ? true : false}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Sucursal*</Form.Label>
              <Select
                options={sucursalesSelect}
                name="sucursales_form"
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={onChangeSucursalForm}
                defaultValue={
                  agenda
                    ? sucursalesSelect.filter(
                        (sucursal) => sucursal.value == agenda.row.sucursal.id
                      )
                    : selectedSucursales.length == 1
                    ? [
                        {
                          value: selectedSucursales[0].value,
                          label: selectedSucursales[0].label,
                        },
                      ]
                    : undefined
                }
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Anotaciones</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Anotaciones"
                onChange={(e) => setNota(e.target.value)}
                value={nota}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleEventModalClose}
            >
              Cerrar
            </Button>
            <Button type="submit" variant="secondary">
              {agenda ? "Actualizar" : "Agregar"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <AgregarPaciente />
    </>
  );
};
