import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Modal, Button, Form, Col } from "@themesberg/react-bootstrap";
import {
  abrirCajaAction,
  cerrarCajaAction,
  getCajaAction,
  getCajaEstadisticasAction,
  showModalCajaAction,
} from "../../actions/cajaActions";
import { getCajaMovimientosAction } from "../../actions/cajaMovimientosActions";
import { MultiSelect } from "react-multi-select-component";
import clienteAxios from "../../config/axios";

export default (props) => {
  const dispatch = useDispatch();

  const { aperturaCaja, cajas, showModalCaja } = useSelector(
    (state) => state.caja
  );

  const [dinero_caja, guardarDineroCaja] = useState(0);
  const [saldo_final_reportado, guardarSaldoFinal] = useState(0);
  const [diferencia_caja, guardarDiferencia] = useState(0);
  const [caja, setCaja] = useState(null);
  const [compartirCajaUsuarios, setCompartirCajaUsuarios] = useState([]);
  const [usuariosAbrirCaja, setUsuariosAbrirCaja] = useState([]);

  useEffect(() => {
    if (caja == null && cajas.length > 0) {
      setCaja(cajas[0]);
    }
  }, [cajas]);

  useEffect(() => {
    getUsuariosCompartirCaja();
  }, []);

  const handleClose = () => {
    dispatch(showModalCajaAction(false));
  };

  const onChangeDineroCajaForm = (e) => {
    guardarDineroCaja(e.target.value);
  };

  const onChangeSaldoFinal = (e) => {
    const saldo_final = parseFloat(e.target.value);
    guardarSaldoFinal(saldo_final);

    const diferencia = parseFloat(saldo_final - aperturaCaja.saldo).toFixed(2);
    guardarDiferencia(diferencia);
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (props.accion == "cerrar") {
      dispatch(cerrarCajaAction(dinero_caja, saldo_final_reportado));
    } else if (props.accion == "abrir") {
      const idUsuariosCompartirCaja = compartirCajaUsuarios.map(
        (usuario) => usuario.value
      );

      dispatch(abrirCajaAction(caja?.id, dinero_caja, idUsuariosCompartirCaja));
      dispatch(getCajaEstadisticasAction());
      dispatch(getCajaMovimientosAction());
    }
  };

  const onChangeCaja = (e) => {
    const cajaSeleccionada = cajas.filter((caja) => caja.id == e.target.value);
    setCaja(cajaSeleccionada[0]);
  };

  const onChangeCompartirCaja = (opcionesSeleccionadas) => {
    setCompartirCajaUsuarios(opcionesSeleccionadas);
  };

  const getUsuariosCompartirCaja = () => {
    clienteAxios
      .get("typeahead/usuariosPermisoAbrirCaja?query=**")
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          setUsuariosAbrirCaja(respuesta.data.data);
        }
      });
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="md"
        centered
        show={showModalCaja}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            {props.accion == "cerrar" ? "Cerrar Caja" : "Abrir Caja"}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={submitForm}>
          <Modal.Body>
            <Form.Group className="mb-3">
              {cajas && Array.isArray(cajas) && props.accion != "cerrar" && (
                <Col>
                  <Form.Label>Caja</Form.Label>
                  <Form.Select
                    aria-label="Todas las cajas"
                    onChange={(e) => onChangeCaja(e)}
                  >
                    {cajas.map((caja) => {
                      return (
                        <>
                          <option value={caja.id}>
                            Caja {caja.numero_caja} | {caja.descripcion}
                          </option>
                        </>
                      );
                    })}
                  </Form.Select>
                </Col>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Fondo en caja chica ($)</Form.Label>
              <Form.Control
                type="number"
                name="dinero_caja"
                step="any"
                onChange={onChangeDineroCajaForm}
                min="0"
              />
            </Form.Group>
            {props.accion != "cerrar" && (
              <>
                <Form.Group>
                  <Form.Label>Compartir caja con:</Form.Label>
                  <MultiSelect
                    options={usuariosAbrirCaja}
                    value={compartirCajaUsuarios}
                    onChange={onChangeCompartirCaja}
                    labelledBy="Selecciona uno o mas filtros"
                    hasSelectAll={false}
                    overrideStrings={{
                      selectSomeItems: "Buscar todo",
                      allItemsAreSelected:
                        "Todos los usuarios fueron seleccionados",
                    }}
                  />
                </Form.Group>
              </>
            )}
            {/* props.accion == 'cerrar' &&
              <>
                <Form.Group className='mb-3'>
                  <Form.Label>Saldo final a reportar ($)</Form.Label>
                  <Form.Control
                    type='number'
                    name='saldo_final_reportado'
                    onChange={onChangeSaldoFinal}
                    min='0'
                    step="any"
                  />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Saldo final totalizado por el sistema ($)</Form.Label>
                  <Form.Control
                    type='number'
                    value={caja.saldo}
                    disabled
                  />
                </Form.Group>
                <Form.Group className='mb-3'>
                  { (diferencia_caja == 0 || isNaN(diferencia_caja)) &&
                    <Form.Label>Diferencia de caja: ($)</Form.Label>
                  }
                  { diferencia_caja > 0 &&
                    <Form.Label className='text-success'>Diferencia de caja. Esta sobrando: ($)</Form.Label>
                  }
                  { diferencia_caja < 0 &&
                    <Form.Label className='text-danger'>Diferencia de caja. Esta faltando: ($)</Form.Label>
                  }
                  <Form.Control
                    type='number'
                    value={diferencia_caja}
                    disabled
                  />
                </Form.Group>
              </>
                */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <Button type="submit" variant="secondary">
              {props.accion == "cerrar" ? "Cerrar caja" : "Abrir"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
