import axios from 'axios';
import { logout } from '../actions/authActions';
import Swal from 'sweetalert2';
import store from '../store';

const {dispatch} = store;

const clienteAxios = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

clienteAxios.interceptors.request.use(function (config) {
    let user = localStorage.getItem('user');
    if(user != null){
        user = JSON.parse(user);
        config.headers.Authorization =  user.token ? `Bearer ${user.token}` : '';
        config.headers.IdSucursal = user.id_sucursal;
    }

    return config;
});

clienteAxios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(error.response.data.errors) {
        let errors = [];
        for (var [key, item] of Object.entries(error.response.data.errors)) {
            errors.push(item);
        }
        
        Swal.fire('UPS', errors.join(" | ") , 'error');
    }

    switch (error.response.status) {
        case 401:
            localStorage.removeItem("user");
            Swal.fire('Sesión expirada', 'Vuelva a iniciar sesión.' , 'info');
            dispatch(logout());
            break;
        case 400:
        case 500:
        case 403:
            Swal.fire('UPS', error.response.data.message , 'error');
            return Promise.reject(error);
        default:
            return Promise.reject(error);
    }
});

export default clienteAxios;