import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Card,Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from '../../components/DataTable';

import { 
    getUsuariosAction, 
    obtenerUsuarioEditar, 
    comenzarAgregarUsuario,
    eliminarUsuarioAction
} from '../../actions/usuarioActions';
import { getRolesAction } from '../../actions/rolesActions';
import { obtenerRolesUsuarioAction } from '../../actions/usuarioRolesActions';

import EditarUsuario from './EditarUsuario';
import AgregarUsuario from './AgregarUsuario';
import RolesUsuario from './RolesUsuario';
import { ConfirmModal } from '../../components/ConfirmModal';
import { columnaSucursalDT } from "../../helpers";

export default () => {
    const dispatch = useDispatch();

    const { loadingUsuarios, usuario, usuarios } = useSelector(state => state.usuario);

    useEffect(() => {
        const getUsuarios = () => dispatch(getUsuariosAction());
        getUsuarios();
    }, [])

    const columns = [
        {
            key: "id",
            text: "ID",
            className: "id",
            align: "left",
            sortable: true
        },
        {
            key: "username",
            text: "Usuario",
            className: "username",
            align: "left",
            sortable: true,
        },
        {
            key: "name",
            text: "Nombre",
            className: "name",
            sortable: true
        },
        {
            key: "email",
            text: "Email",
            className: "email",
            align: "left",
            sortable: true
        }
    ];
    
    const state = {
        records: usuarios
    }
    
    const extraButtons =[
        {
            className:"btn btn-info",
            title:"Agregar usuario",
            children:[
                <span>
                    <i className="fas fa-user-plus"></i> Nuevo
                </span>
            ],
            onClick:(event)=>{
                dispatch(comenzarAgregarUsuario());
            },
        },
    ]

    const editRecord = (record) => {
        dispatch(obtenerUsuarioEditar(record));
    }

    const deleteRecord = (record) => {
        const callbacks = [
            {
                event: dispatch,
                action: eliminarUsuarioAction,
                args: [record]
            }
        ]

        ConfirmModal(callbacks);
    }

    const rolesUser = (record) => {
        dispatch(getRolesAction());
        dispatch(obtenerRolesUsuarioAction(record));
    }

    const acciones = [{
        description: 'Roles del usuario',
        icon: 'fas fa-user-tag',
        className: 'btn btn-tertiary btn-sm',
        event: rolesUser,
        style: {marginRight: '5px'}
    }];

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Usuarios</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body>
                    <h5 className="mb-4">Usuarios</h5>
                    <DataTable 
                        columns = {columns}
                        editRecord = {editRecord}
                        deleteRecord = {deleteRecord}
                        acciones = {acciones}
                        state = {state}
                        extraButtons = {extraButtons}
                        loading = {loadingUsuarios}
                    />
                </Card.Body>
            </Card>

            <EditarUsuario usuario={usuario}/>
            <AgregarUsuario/>
            <RolesUsuario/>
        </>
  );
};