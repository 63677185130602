import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  Row,
  Col,
  ListGroup,
  Table,
  Badge,
  Tabs,
  Tab,
  Button,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
  Form,
} from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { estadisticasCaja } from "../../data/charts";
import { CircleChartWidget } from "../../components/Widgets";
import {
  cambiarCajaAction,
  getCajaAction,
  getCajaEstadisticasAction,
  getCajasAction,
  showModalCajaAction,
  showModalCajaCorteZAction,
} from "../../actions/cajaActions";
import {
  getCajaMovimientosAction,
  getTipoMovimientosAction,
  showModalCajaMovimientoAction,
} from "../../actions/cajaMovimientosActions";
import TableMovimientosCaja from "./TableMovimientosCaja";
import ModalAgregarMovimiento from "./ModalAgregarMovimiento";
import ModalAperturaCierreCaja from "./ModalAperturaCierreCaja";
import ModalCorteZCaja from "./ModalCorteZCaja";
import { numberFormat } from "../../helpers";
import { getVentasAction, imprimirVenta } from "../../actions/ventaActions";
import ModalHistorialVentasDetalle from "../Venta/ModalHistorialVentasDetalle";
import ReactDatatable from "@mkikets/react-datatable";
import { actualizarStorageAction } from "../../actions/authActions";

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCajaAction());
    dispatch(getCajaEstadisticasAction());
    dispatch(getCajaMovimientosAction());
    dispatch(getCajasAction());
  }, []);

  const [state, setState] = useState({
    accionCaja: "",
    periodoCorteZ: "",
    detalles: [],
    showModalDetalles: false,
  });

  const { aperturaCaja, cajas, caja, estadisticas } = useSelector(
    (state) => state.caja
  );
  const { movimientos } = useSelector((state) => state.cajaMovimientos);
  const { ventas, loadingVentas } = useSelector((state) => state.venta);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getCajaAction());
    dispatch(getCajaEstadisticasAction());
  }, [movimientos]);

  useEffect(() => {
    if (aperturaCaja.idCaja) {
      dispatch(getVentasAction({ id_caja_apertura: aperturaCaja.idCaja }));
    }
  }, [aperturaCaja.idCaja]);

  useEffect(() => {
    dispatch(getCajaMovimientosAction());
  }, [caja]);

  const MovimientosIngresos = () => {
    let ingresos = [];
    if (movimientos.length > 0) {
      ingresos = movimientos.filter(
        (movimiento) =>
          movimiento.tipo_movimiento?.tipo_contabilidad == "INGRESO"
      );
    }

    return <TableMovimientosCaja movimientos={ingresos} />;
  };

  const MovimientosEgresos = () => {
    const columnas = [
      {
        key: "egreso",
        text: "Egreso",
        className: "egreso",
        align: "left",
        sortable: true,
      },
    ];

    let egresos = [];

    if (movimientos.length > 0) {
      egresos = movimientos.filter(
        (movimiento) =>
          movimiento.tipo_movimiento?.tipo_contabilidad == "EGRESO"
      );
    }

    return <TableMovimientosCaja movimientos={egresos} />;
  };

  const MovimientosRetiros = () => {
    const columnas = [
      {
        key: "retiros",
        text: "Retiros",
        align: "left",
        sortable: true,
      },
    ];

    let retiros = [];

    if (movimientos.length > 0) {
      retiros = movimientos.filter(
        (movimiento) =>
          movimiento.tipo_movimiento?.tipo_contabilidad == "RETIRO"
      );
    }

    return <TableMovimientosCaja movimientos={retiros} />;
  };

  const agregarMovimiento = () => {
    dispatch(showModalCajaMovimientoAction(true));
    dispatch(getTipoMovimientosAction());
  };

  const cerrarCaja = () => {
    setState({
      ...state,
      accionCaja: "cerrar",
    });

    dispatch(showModalCajaAction(true));
  };

  const abrirCaja = () => {
    setState({
      ...state,
      accionCaja: "abrir",
    });

    dispatch(showModalCajaAction(true));
  };

  const cajaCorteZDiario = () => {
    dispatch(showModalCajaCorteZAction(true));
    setState({
      ...state,
      periodoCorteZ: "diario",
    });
  };

  const cajaCorteZMensual = () => {
    dispatch(showModalCajaCorteZAction(true));
    setState({
      ...state,
      periodoCorteZ: "mensual",
    });
  };

  const showDetalles = (detalle) => {
    setState({ ...state, showModalDetalles: true, detalles: detalle });
  };

  const reimprimir = (id) => {
    dispatch(imprimirVenta(id));
  };

  const columnsVentas = [
    {
      key: "id",
      text: "ID",
      align: "left",
      sortable: true,
    },
    {
      text: "Nombre Vendedor",
      sortable: true,
      cell: (record) => {
        return record.audits ? record.audits[0]?.user?.name : "NO ENCONTRADO";
      },
    },
    {
      text: "Cliente",
      sortable: true,
      cell: (record) => {
        return record.cliente?.nombre_completo;
      },
    },
    {
      key: "fecha",
      text: "Fecha",
      sortable: true,
    },
    {
      key: "metodo_pago.metodo",
      text: "Metodo de pago",
      sortable: true,
      cell: (record) => {
        return record.metodo_pago?.metodo;
      },
    },
    {
      key: "total",
      text: "Total",
      sortable: true,
    },
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Detalle</Tooltip>}
              >
                <button
                  onClick={() => showDetalles(record.detalle)}
                  className="btn btn-info btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i class="fas fa-boxes"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
          </>
        );
      },
    },
  ];

  const configVentas = {
    page_size: 5,
    length_menu: [5, 10, 25],
    show_filter: false,
    show_info: false,
  };

  const onChangeCaja = (e) => {
    const cajaSeleccionada = cajas.filter((caja) => caja.id == e.target.value);
    dispatch(cambiarCajaAction(cajaSeleccionada[0] ?? null));
  };

  const FilaCajaHeader = ({apertura}) => {
    return (
      <>
        <tr>
          <th
            className="border-0"
            style={{
              width: "5%",
              backgroundColor: "#dce6ec",
            }}
          >
            <span style={{ marginRight: "15px" }}>
              {" "}
              {apertura.descripcionCaja}{" "}
            </span>
            <span style={{ marginRight: "15px" }}>
              {" "}
              Fecha apertura: {apertura.fechaCaja}{" "}
            </span>

            <span className="mr-4">
              {" "}
              Estatus:{" "}
              {apertura.estatusCaja == "A" ? (
                <Badge bg="success" className="p-2">
                  Abierta
                </Badge>
              ) : (
                <Badge bg="danger" className="p-2">
                  Cerrada
                </Badge>
              )}{" "}
            </span>
          </th>
        </tr>
      </>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Venta</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <h5 className="mb-4" style={{ marginLeft: "10px" }}>
        {" "}
        Administrar Caja{" "}
      </h5>

      <Row>
        <Col>
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
          >
            <Card.Body>
              <Row className="mb-2">
                {cajas && Array.isArray(cajas) && (
                  <Col>
                    <h5>Caja seleccionada</h5>
                    <Form.Select
                      aria-label="Todas las cajas"
                      onChange={(e) => onChangeCaja(e)}
                    >
                      <option>Todas las cajas</option>
                      {cajas.map((caja) => {
                        return (
                          <>
                            <option value={caja.id} selected={caja.seleccionada}>
                              Caja {caja.numero_caja} | {caja.descripcion}
                            </option>
                          </>
                        );
                      })}
                    </Form.Select>
                  </Col>
                )}
                <Col>
                  <Dropdown className="float-end">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Cortes de Caja <i class="fas fa-chevron-down"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={cajaCorteZDiario}>
                        Corte Z - Diario
                      </Dropdown.Item>
                      <Dropdown.Item onClick={cajaCorteZMensual}>
                        Corte Z - Mensual
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    variant="info"
                    className="float-end"
                    style={{ marginRight: "5px" }}
                    onClick={agregarMovimiento}
                    disabled={
                      aperturaCaja.estatusCaja == "C" ||
                      Object.keys(aperturaCaja).length == 0 ||
                      aperturaCaja.estatusCaja == null ||
                      Array.isArray(aperturaCaja.estatusCaja)
                    }
                  >
                    Agregar movimiento
                  </Button>
                  {aperturaCaja.estatusCaja == "A" ? (
                    <Button
                      variant="danger"
                      className="float-end"
                      style={{ marginRight: "5px" }}
                      onClick={cerrarCaja}
                    >
                      Cerrar caja
                    </Button>
                  ) : (
                    <Button
                      variant="success"
                      className="float-end"
                      style={{ marginRight: "5px" }}
                      onClick={abrirCaja}
                    >
                      Abrir caja
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <ListGroup horizontal>
                    <ListGroup.Item
                      className="w-50 rounded-0"
                      style={{ backgroundColor: "#1b998b", color: "#fff" }}
                    >
                      Monto inicial
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="w-50 text-left rounded-0"
                      style={{ backgroundColor: "#f7f7f7" }}
                    >
                      <strong>{numberFormat(aperturaCaja.montoInicial)}</strong>
                    </ListGroup.Item>
                  </ListGroup>
                  <ListGroup horizontal>
                    <ListGroup.Item
                      className="w-50 rounded-0"
                      style={{ backgroundColor: "#17a5ce", color: "#fff" }}
                    >
                      Ingresos
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="w-50 text-left rounded-0"
                      style={{ backgroundColor: "#f7f7f7" }}
                    >
                      <strong>
                        {numberFormat(aperturaCaja.ingresosTotales)}
                      </strong>
                    </ListGroup.Item>
                  </ListGroup>
                  <ListGroup horizontal>
                    <ListGroup.Item
                      className="w-50 rounded-0"
                      style={{ backgroundColor: "#262b40", color: "#fff" }}
                    >
                      Gastos
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="w-50 text-left rounded-0"
                      style={{ backgroundColor: "#f7f7f7" }}
                    >
                      <strong>{numberFormat(aperturaCaja.total_gastos)}</strong>
                    </ListGroup.Item>
                  </ListGroup>

                  <br />

                  <Table
                    responsive
                    className="table-centered rounded text-center"
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      background: "#f5f8fb",
                    }}
                  >
                    <thead className="thead-light">
                      <tr className="border-bottom">
                        <th className="border-0" style={{ width: "5%" }}>
                          VENTA EN EFECTIVO <br />
                          <strong className="text-success">
                            {numberFormat(aperturaCaja.ingresosEfectivo)}
                          </strong>
                        </th>
                        <th className="border-0" style={{ width: "5%" }}>
                          VENTA CON TARJETA <br />
                          <strong className="text-success">
                            {numberFormat(aperturaCaja.ingresosTarjeta)}
                          </strong>
                        </th>
                        <th className="border-0" style={{ width: "5%" }}>
                          VENTA CON TRANSF. <br />
                          <strong className="text-success">
                            {numberFormat(aperturaCaja.ingresosTransferencia)}
                          </strong>
                        </th>
                        <th className="border-0" style={{ width: "5%" }}>
                          TOTAL VENTA <br />
                          <strong className="text-success">
                            {numberFormat(aperturaCaja.ingresosTotales)}
                          </strong>
                        </th>
                      </tr>
                      <tr className="border-bottom">
                        <th className="border-0" style={{ width: "5%" }}>
                          GASTOS EN EFECTIVO <br />
                          <strong className="text-danger">
                            {numberFormat(aperturaCaja.gastos_efectivo)}
                          </strong>
                        </th>
                        <th className="border-0" style={{ width: "5%" }}>
                          GASTOS A CRÉDITO <br />
                          <strong className="text-danger">
                            {numberFormat(aperturaCaja.gastos_credito)}
                          </strong>
                        </th>
                        <th className="border-0" style={{ width: "5%" }}>
                          TOTAL GASTOS <br />
                          <strong className="text-danger">
                            {numberFormat(aperturaCaja.total_gastos)}
                          </strong>
                        </th>
                      </tr>
                      <tr className="border-bottom">
                        <th className="border-0" style={{ width: "50%" }}>
                          TOTAL DE EFECTIVO EN CAJA <br />
                          <strong className="text-info">
                            {numberFormat(
                              aperturaCaja.montoInicial +
                                aperturaCaja.ingresosEfectivo +
                                aperturaCaja.depositos -
                                aperturaCaja.gastos_efectivo -
                                aperturaCaja.retiros
                            )}
                          </strong>
                        </th>
                        <th className="border-0" style={{ width: "50%" }}>
                          INGRESO SALDO <br />
                          <strong className="text-info">
                            {numberFormat(aperturaCaja.saldo)}
                          </strong>
                        </th>
                        <th className="border-0" style={{ width: "50%" }}>
                          RETIROS <br />
                          <strong className="text-info">
                            {numberFormat(aperturaCaja.retiros)}
                          </strong>
                        </th>
                        <th className="border-0" style={{ width: "40%" }}>
                          SALDO FINAL <br />
                          <strong className="text-info">
                            {numberFormat(aperturaCaja.montoInicialMasSaldo)}
                          </strong>
                        </th>
                      </tr>
                    </thead>
                  </Table>
                </Col>

                <Col xs={12} md={6} className="mt-1">
                  <Row style={{ marginBottom: "10px" }}>
                    <Col>
                      <Table
                        responsive
                        className="table-centered rounded text-center"
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }}
                      >
                        <thead className="thead-light">
                          {Array.isArray(aperturaCaja.idCaja) ? (
                            aperturaCaja.idCaja.map(function (id, index) {
                              console.log(index)
                              const aperturaParams = {
                                estatusCaja: aperturaCaja.estatusCaja[index],
                                fechaCaja: aperturaCaja.fechaCaja[index],
                                descripcionCaja: aperturaCaja.descripcionCaja[index]
                              };
                              return <FilaCajaHeader apertura={aperturaParams} />;
                            })
                          ) : (
                            <FilaCajaHeader apertura={aperturaCaja} />
                          )}
                        </thead>
                      </Table>
                    </Col>
                  </Row>
                  <Row
                    style={{ marginTop: "20px" }}
                    className="table-wrapper table-responsive"
                  >
                    <h5>Ventas de la caja</h5>
                    {loadingVentas ? (
                      <div
                        class="spinner-border text-info mx-auto d-block"
                        role="status"
                      >
                        <span class="visually-hidden">Cargando...</span>
                      </div>
                    ) : (
                      <ReactDatatable
                        hover
                        records={ventas}
                        columns={columnsVentas}
                        config={configVentas}
                      />
                    )}
                  </Row>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col>
                  <Card
                    border="light"
                    className="table-wrapper table-responsive shadow-sm"
                  >
                    <Card.Body>
                      <h5 className="mb-3">Movimientos caja</h5>
                      <Tabs
                        defaultActiveKey="ingresos"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                      >
                        <Tab eventKey="ingresos" title="Ingresos">
                          <MovimientosIngresos />
                        </Tab>
                        <Tab eventKey="gastos" title="Gastos">
                          <MovimientosEgresos />
                        </Tab>
                        <Tab eventKey="retiros" title="Retiros">
                          <MovimientosRetiros />
                        </Tab>
                      </Tabs>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <ModalAgregarMovimiento />
      <ModalAperturaCierreCaja accion={state.accionCaja} />
      <ModalCorteZCaja periodo={state.periodoCorteZ} />
      <ModalHistorialVentasDetalle
        detalles={state.detalles}
        showModal={state.showModalDetalles}
        onHide={() => setState({ ...state, showModalDetalles: false })}
      />
    </>
  );
};
